import { Motion } from 'solid-motionone'
import { transform } from 'framer-motion/dom'
import { scroll } from 'motion'
import {
  createContext,
  createEffect,
  createSignal,
  onMount,
  useContext,
  type Accessor,
  type ParentComponent,
} from 'solid-js'

const clamp = (number: number, min: number, max: number) => {
  return Math.min(Math.max(number, min), max)
}

function createBoundedScroll(bounds: number) {
  let [scrollY, setScrollY] = createSignal(0)
  let [scrollYBounded, setScrollYBounded] = createSignal(0)
  let [scrollYBoundedProgress, setScrollYBoundedProgress] = createSignal(0)

  onMount(() => {
    scroll(({ y }) => {
      let previous = scrollY()
      let diff = y.current - previous
      let newScrollYBounded = scrollYBounded() + diff

      setScrollY(y.current)
      setScrollYBounded(clamp(newScrollYBounded, 0, bounds))
      setScrollYBoundedProgress(
        transform(scrollYBounded(), [0, bounds], [0, 1]),
      )
    })
  })

  return { scrollYBounded, scrollYBoundedProgress }
}

type Context = {
  scrollYBoundedProgress: Accessor<number>
  scrollYBoundedProgressThrottled: Accessor<number>
}

const context = createContext<Context>()

export const ResizableHeader: ParentComponent = (props) => {
  let { scrollYBoundedProgress } = createBoundedScroll(400)
  let [scrollYBoundedProgressThrottled, setScrollYBoundedProgressThrottled] =
    createSignal(0)
  let [fromHeight, setFromHeight] = createSignal(128)
  let [toHeight, setToHeight] = createSignal(80)
  let [fromBg, setFromBg] = createSignal('')
  let headerRef: HTMLElement | undefined = undefined

  onMount(() => {
    console.log(fromBg())
    setFromBg(
      getComputedStyle(headerRef!)
        .getPropertyValue('--from-bg')
        .replace(/,/g, ' '),
    )
    console.log(fromBg())
    setFromHeight(parseInt(getComputedStyle(headerRef!).height))
    setToHeight(
      parseInt(getComputedStyle(headerRef!).getPropertyValue('--to-height')),
    )
  })

  createEffect(() => {
    // setFromBg(
    //   getComputedStyle(headerRef!)
    //     .getPropertyValue('--from-bg')
    //     .replace(/,/g, ' '),
    // )
    setScrollYBoundedProgressThrottled(
      transform(scrollYBoundedProgress(), [0, 0.75, 1], [0, 0, 1]),
    )
  })

  let ctx: Context = { scrollYBoundedProgress, scrollYBoundedProgressThrottled }

  return (
    <Motion.header
      animate={{
        'background-color': `rgb(${fromBg()} / ${transform(
          scrollYBoundedProgress(),
          [0, 1],
          [1, 0.8],
        )})`,
        height: `${transform(
          scrollYBoundedProgressThrottled(),
          [0, 1],
          [fromHeight(), toHeight()],
        )}px`,
      }}
      class="
        fixed inset-0 z-40 mx-auto h-24 w-full bg-white shadow backdrop-blur-md
        [--from-bg:255,255,255] [--to-height:60]
        dark:bg-gray-900 dark:[--from-bg:51,51,51]
        md:h-32 md:[--to-height:80]"
      initial={false}
      ref={headerRef}
    >
      <context.Provider value={ctx}>{props.children}</context.Provider>
    </Motion.header>
  )
}

export function useResizable() {
  const ctx = useContext(context)
  if (!ctx) {
    throw new Error('No Resizable context')
  }

  return ctx
}
