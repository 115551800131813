import { Motion, Presence } from 'solid-motionone'
import { transform } from 'framer-motion/dom'
import {
  createContext,
  createSignal,
  For,
  Show,
  useContext,
  type Accessor,
  type Component,
  type ParentComponent,
} from 'solid-js'

import { ToggleMenu } from '~/components/common/ToggleMenu'
import { ToggleTheme } from '~/components/common/ToggleTheme'
import { Logo } from '~/components/Logo'
import { useResizable } from '~/components/widgets/ResizableHeader'
import { getHomePermalink } from '~/utils/permalinks'

interface Link {
  ariaLabel?: string
  href?: string
  icon?: string
  text?: string
}

interface MenuLink extends Link {
  links?: Array<Link>
}

type Context = {
  menuState: Accessor<'closed' | 'open'>
  toggleState: () => {}
}

const context = createContext<Context>()

export const Navigation: Component<{
  links?: Array<MenuLink>
  showRssFeed?: boolean
  showToggleTheme?: boolean
}> = (props) => {
  let [menuState, setMenuState] = createSignal<'closed' | 'open'>('closed')
  const toggleState = () =>
    setMenuState(menuState() === 'closed' ? 'open' : 'closed')
  const { scrollYBoundedProgressThrottled } = useResizable()

  let ctx: Context = { menuState, toggleState }

  return (
    <context.Provider value={ctx}>
      <nav
        aria-label="Global"
        class="mx-auto flex h-full max-w-7xl content-center items-center justify-between p-6 lg:px-8"
      >
        <div class="flex lg:flex-1">
          <a
            class="flex items-center"
            href={getHomePermalink()}
          >
            <span class="sr-only">Kodehort Limited</span>
            <Motion.div
              animate={{
                scale: transform(
                  scrollYBoundedProgressThrottled(),
                  [0, 1],
                  [1, 0.7],
                ),
              }}
              class="origin-left"
            >
              <Logo class="h-8 w-auto sm:h-12" />
            </Motion.div>
          </a>
        </div>
        <div class="z-50 flex lg:hidden">
          <Show when={props.links?.length  && props.links?.length > 1 }>
            <ToggleMenu />
          </Show>
        </div>
        <div class="hidden lg:flex lg:gap-x-12">
          <For each={props.links}>
            {(link) => (
              <>
                <Show when={link.links?.length && link.links?.length > 1}>
                  <div class="relative">
                    <button
                      aria-expanded="false"
                      class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900"
                      type="button"
                    >
                      {link.text}
                      <svg
                        aria-hidden="true"
                        class="h-5 w-5 flex-none text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </button>

                    <div class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                      <div class="p-4">
                        <For each={link.links}>
                          {(link) => (
                            <div class="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                              <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                <svg
                                  aria-hidden="true"
                                  class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              <div class="flex-auto">
                                <a
                                  class="block font-semibold text-gray-900 dark:text-gray-100"
                                  href="#"
                                >
                                  {link.text}
                                  <span class="absolute inset-0"></span>
                                </a>
                                <p class="mt-1 text-gray-600">
                                  {link.ariaLabel}
                                </p>
                              </div>
                            </div>
                          )}
                        </For>
                      </div>
                    </div>
                  </div>
                </Show>
                <Show when={!link.links}>
                  <a
                    class="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100"
                    href={link.href}
                    title={link.ariaLabel}
                  >
                    {link.text}
                  </a>
                </Show>
              </>
            )}
          </For>
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <ToggleTheme />
        </div>
      </nav>
      <MobileMenu
        links={props.links}
        state={menuState()}
      />
    </context.Provider>
  )
}

export function useMenuState() {
  const ctx = useContext(context)
  if (!ctx) {
    throw new Error('No Navigation context')
  }

  return ctx
}

const MobileMenu: Component<{
  links?: Array<MenuLink>
  state: 'closed' | 'open'
}> = (props) => {
  return (
    <Presence exitBeforeEnter>
      <Show when={props.state === 'open'}>
        <div
          aria-modal="true"
          class="fixed inset-0 lg:hidden"
          role="dialog"
        >
          <Motion.div
            animate={{ opacity: 0.75 }}
            class="absolute inset-0 z-30 h-screen overflow-y-scroll overscroll-y-none bg-gray-700"
            initial={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          ></Motion.div>
          <Motion.div
            animate={{
              height: 'var(--height-to)',
              opacity: 1,
              transition: { delay: 0.2, duration: 0.3 },
              width: 'var(--width-to)',
            }}
            class="absolute inset-y-0 right-0 z-50 w-full
            overflow-y-scroll overscroll-y-none bg-white px-6 py-6
            [--height-from:0vh] [--height-to:100vh]
            [--width-from:100%] [--width-to:100%]
            dark:bg-gray-800 dark:text-gray-200 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10
            sm:[--height-from:100vh] sm:[--height-to:100vh]
            sm:[--width-from:0vw] sm:[--width-to:24rem]"
            exit={{
              height: 'var(--height-from)',
              opacity: 0,
              width: 'var(--width-from)',
            }}
            initial={{
              height: 'var(--height-from)',
              opacity: 0,
              width: 'var(--width-from)',
            }}
          >
            <div class="flex items-center justify-between">
              <a
                class="-m-1.5 p-1.5"
                href={getHomePermalink()}
                title="Kodehort Limited"
              >
                <span class="sr-only">Kodehort Limited</span>
                <Logo class="h-8 w-auto sm:h-12" />
              </a>
            </div>
            <div class="mt-6 flow-root">
              <div class="-my-6 divide-y divide-gray-500/10">
                <div class="space-y-2 py-6">
                  <For each={props.links}>
                    {(link, index) => (
                      <>
                        <Show when={link.links?.length}>
                          <div class="-mx-3">
                            <button
                              aria-controls={`disclosure-${index}`}
                              aria-expanded="false"
                              class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                              type="button"
                            >
                              {link.text}
                              <svg
                                aria-hidden="true"
                                class="h-5 w-5 flex-none"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  clip-rule="evenodd"
                                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                  fill-rule="evenodd"
                                />
                              </svg>
                            </button>
                            <div
                              class="mt-2 space-y-2"
                              id={`disclosure-${index}`}
                            >
                              <For each={link.links}>
                                {(link) => (
                                  <MenuA
                                    href={link.href}
                                    title={link.ariaLabel}
                                  >
                                    {link.text}
                                  </MenuA>
                                )}
                              </For>
                            </div>
                          </div>
                        </Show>
                        <Show when={!link.links}>
                          <MenuA
                            href={link.href}
                            title={link.ariaLabel}
                          >
                            {link.text}
                          </MenuA>
                        </Show>
                      </>
                    )}
                  </For>
                </div>
              </div>
            </div>
          </Motion.div>
        </div>
      </Show>
    </Presence>
  )
}

export const MenuA: ParentComponent<{ href?: string; title?: string }> = (
  props,
) => {
  const { toggleState } = useMenuState()

  return (
    <a
      class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 dark:text-gray-100"
      href={props.href}
      onClick={toggleState}
      title={props.title}
    >
      {props.children}
    </a>
  )
}
